import React from "react";
import { graphql, Link } from "gatsby";
import classnames from "classnames";
import Layout from "../components/layout";
import BlogMeta from "../components/blog/meta";
import RichText from "../components/richtext";
import SEO from "../components/seo";

const BlogCard = ({
  post_title,
  post_type,
  post_date,
  post_body,
  author,
  post_image,
  uid,
  index
}) => (
  <div
    className={classnames("md:w-1/2 py-4", {
      "md:pl-4": index % 2 !== 0,
      "md:pr-4": index % 2 === 0 || index === 0
    })}
  >
    <Link
      to={`/${uid}`}
      className="block bg-cream-light p-6 h-full flex items-center"
    >
      <div>
        {post_image.thumbnails.collection.url && (
          <img src={post_image.thumbnails.collection.url} className="mb-3" />
        )}
        <BlogMeta post_type={post_type} post_date={post_date} />
        <h3 className="text-2xl font-serif leading-tight pb-2">
          {post_title.text}
        </h3>
        <p className="font-serif">By {author}</p>
        {post_body.text && (
          <p className="text-sm mt-4">
            {post_body.text.slice(0, 200)}
            {"..."}
          </p>
        )}
      </div>
    </Link>
  </div>
);

const Blog = ({ data }) => (
  <Layout>
    <SEO
      title={data.prismicBlog.data.meta_title}
      description={data.prismicBlog.data.meta_description}
    />
    <div className="max-w-4xl mx-auto mb-10">
      <div className="border-b-2 text-center mb-4">
        <h1 className="text-3xl md:text-5xl font-serif pb-3">
          {data.prismicBlog.data.title.text}
        </h1>
      </div>
      <RichText
        html={data.prismicBlog.data.intro.html}
        className="text-center"
      />
      <div className="flex flex-wrap">
        {data.allPrismicBlogPost.nodes.map(({ data, uid }, index) => (
          <BlogCard {...data} uid={uid} key={index} index={index} />
        ))}
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  {
    allPrismicBlogPost(sort: { fields: data___post_date, order: DESC }) {
      nodes {
        uid
        data {
          post_title {
            text
          }
          post_type
          post_date
          post_body {
            text
          }
          author
          post_image {
            thumbnails {
              collection {
                url
                alt
              }
            }
          }
        }
      }
    }
    prismicBlog {
      data {
        title {
          text
        }
        intro {
          html
        }
        meta_title
        meta_description
      }
    }
  }
`;

export default Blog;
