import React from "react";
import moment from "moment";

const BlogMeta = ({ post_type, post_date }) => (
  <div className="flex justify-between uppercase tracking-wide font-bold text-xs md:text-sm pb-2">
    <span className="text-dark-lighter">{post_type}</span>
    <span className="text-sage">{moment(post_date, "YYYYMMDD").fromNow()}</span>
  </div>
);

export default BlogMeta;
